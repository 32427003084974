<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-estado">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Actualizar Estado</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                class="form-control form-control-sm"
                v-model="form_estado.estado"
                :class="
                  $v.form_estado.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
                @change="ajustaCamposEstado"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in $parent.listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="justificacion">Justificación</label>
              <textarea
                v-model="form_estado.justificacion"
                cols="30"
                rows="5"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.justificacion.$invalid
                    ? 'is-invalid'
                    : 'is-valid'
                "
              ></textarea>
            </div>
            <div
              class="form-group"
              v-if="
                this.tipo_usuario_cambia_estado == 'HSE' &&
                form_estado.estado == 2
              "
            >
              <label>Fecha Reactivación</label>
              <input
                type="date"
                v-model="form_estado.fecha_reactivacion"
                class="form-control form-control-sm"
                :class="
                  $v.form_estado.fecha_reactivacion
                    ? $v.form_estado.fecha_reactivacion.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                    : ''
                "
                @input="validaFechas"
              />
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_estado.$invalid"
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "OperarioEstado",
  data() {
    return {
      tipo_usuario_cambia_estado: null,
      form_estado: {
        estado: null,
      },
    };
  },
  validations() {
    if (
      this.tipo_usuario_cambia_estado == "HSE" &&
      this.form_estado.estado == 2
    ) {
      return {
        form_estado: {
          estado: {
            required,
          },
          fecha_reactivacion: {
            required,
          },
          justificacion: {
            required,
            minLength: minLength(10),
          },
          entidad_id: {
            required,
          },
          entidad: {
            required,
          },
        },
      };
    } else {
      return {
        form_estado: {
          estado: {
            required,
          },
          justificacion: {
            required,
            minLength: minLength(10),
          },
          entidad_id: {
            required,
          },
          entidad: {
            required,
          },
        },
      };
    }
  },
  methods: {
    save() {
      if (!this.$v.form_estado.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/operarios",
          data: this.form_estado,
        })
          .then((response) => {
            this.form_estado = {};
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "Se realizo el cambio de estado exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
        this.$parent.getIndex();
      }
    },

    llenar_modal(operario, tipo_usuario_cambia_estado) {
      this.tipo_usuario_cambia_estado = tipo_usuario_cambia_estado;
      // this.tipo_usuario_cambia_estado = "algo";
      let justificacion = null;
      if (operario.historicos_estado.length > 0) {
        if (
          operario.historicos_estado[0].perfil_justificacion == "HSE" &&
          this.tipo_usuario_cambia_estado == "HSE"
        ) {
          justificacion = operario.historicos_estado[0].justificacion;
        }
        if (
          operario.historicos_estado[0].perfil_justificacion == "Inspector" &&
          (this.tipo_usuario_cambia_estado == "Inspector" ||
            this.tipo_usuario_cambia_estado == "HSE")
        ) {
          justificacion = operario.historicos_estado[0].justificacion;
        }
      }

      let fecha_reactivacion = null;
      if (operario.estado == 2) {
        fecha_reactivacion = operario.fecha_reactivacion;
      }

      this.form_estado = {
        entidad_id: operario.id,
        entidad: "Operario",
        id: operario.id,
        estado: operario.estado,
        valor_ant: operario.estado,
        justificacion,
        fecha_reactivacion,
        user: this.$store.state.user.id,
        perfil_justificacion: this.tipo_usuario_cambia_estado,
      };
    },

    ajustaCamposEstado() {
      if (this.form_estado.estado == 1) {
        this.form_estado.fecha_reactivacion = "";
      }
    },

    validaFechas() {
      if (this.form_estado.fecha_reactivacion) {
        const fecha_menor = new Date();
        const fecha_mayor = new Date(this.form_estado.fecha_reactivacion);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form_estado.fecha_reactivacion = null;
          this.$swal({
            icon: "error",
            title: `La fecha de reactivación no puede ser menor a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
};
</script>